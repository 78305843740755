import React, { useEffect, useState, useContext } from "react";
import Head from "../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../components/Component";
import { countryOptions, userData } from "./UserData";
import { getDateStructured } from "../../utils/Utils";
import { kycInfo, getUserInfo, CheckVerification } from "../../app/api";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";

const UserProfileRegularPage = ({ sm, updateSm, userInfo, setModal, setKycData }) => {
  // console.log(userInfo);
  const [modalTab, setModalTab] = useState("1");
  // const [u_info, setUserInfo] = useState({});
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    phone: "",
    dob: "",
    address: "",
    address2: "",
    state: "",
    country: "",
  });

  const ctx = useContext(AppContext);

  // const setUserData = async () => {
  //   const uid = localStorage.getItem('uid');
  //   const uinfo = await getUserInfo(uid);
  //   setUserInfo(uinfo);
    
  // }

  const kycInfoData = async () => {
    let uid = localStorage.getItem("uid");

    let res = await kycInfo({
      uid: uid,
    });
    if (res.code == 200) {
      setKycData(res.data);
    }
  };

  useEffect(() => {
    kycInfoData();
    // console.log(userInfo.profile_lock);

    
  }, [formData]);
  // useEffect(() => {
  //   setUserData();
  // }, []);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.fname]: e.target.value });
  };

  // const submitForm = () => {
  //   let submitData = {
  //     ...formData,
  //   };
  //   setUserInfo(submitData);
  //   setModal(false);
  // };


  const [mailVerify, setMailVerify] = useState(false);
  const [mailVerifyText, setMailVerifyText] = useState("Verify Mail");
  const [show, setShow] = useState(false);
  const mailVerified = localStorage.getItem("mail_verified");

  const handleVerifyMail = async () => {
    const uid = localStorage.getItem("uid");
    if (mailVerifyText === "Verifying ...") return;
    try {
      setMailVerifyText("Verifying ...");
      const sendMailRes = await CheckVerification(uid, "sendMail");
      if (sendMailRes.success) {
        if (sendMailRes.message === "Verification email sent successfully.") {
          toast.success(sendMailRes.message);
        }
        setMailVerifyText("Resend Mail");
      } else if (sendMailRes.message === "Your mail address is verified.") {
        localStorage.setItem("mail_verified", 1);
        toast.success(sendMailRes.message);
        setShow(true);
      } else if (
        sendMailRes.message === "Please wait at least 30 seconds before requesting another verification email."
      ) {
        toast.error("Please wait at least 30 seconds before resend mail.");
        setMailVerifyText("Resend Mail");
      } else {
        setMailVerifyText("Resend Mail");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      setMailVerifyText("Resend Mail");
    }
  };

  return (
    <React.Fragment>
      <Head title="Personal Information"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information </BlockTitle>
            <BlockDes>
              <p>Check or update your personal details here.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-end ">
          {(userInfo && userInfo.profile_lock == 0) && (ctx.sts.perms.includes('448') || ctx.sts.perms.includes('9999')) ?
          <Button
          className={` btn btn-primary btn-sm btn-icon float-right text-right d-none d-lg-block pl-2 pr-2`}
                onClick={() => { setModal(true); kycInfoData()}}
          >
              <Icon name="edit-alt"></Icon> <span className="">Edit Profile&nbsp;</span>
            </Button>
            : 
            ''
          }
            <Button
              className={`toggle btn btn-icon btn-trigger d-lg-none mt-n1 float-right ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <div className="nk-data data-list custom-info">
          <div className="data-head bg-primary">
            <h6 className="overline-title text-white">Basic Information</h6>
          </div>
          <div className="data-item c-p-5" >
            <div className="data-col">
              <span className="data-label">First Name</span>
              <span className="data-value">{userInfo.first_name}</span>
            </div>
            <div className="data-col data-col-end">
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
            </div>
          </div>
          <div className="data-item c-p-5" >
            <div className="data-col">
              <span className="data-label">Last Name</span>
              <span className="data-value">{userInfo.last_name}</span>
            </div>
            <div className="data-col data-col-end">
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
            </div>
          </div>
          <div className="data-item c-p-5">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value word-wrap break-word">{userInfo.email}</span>
              <span className="data-more data-col-end disable ">
                {mailVerified == 1 ? (
                  <Icon name="lock-alt"></Icon>
                ) : (
                  <>
                    {mailVerified == 0 && show === false ? (
                      <li
                        className="btntop"
                        style={{
                          width: "217px",
                        }}
                      >
                        <Button
                          className="btn  btn-sm text-white px-3 py-0"
                          style={{ background: "#C70000" }}
                          onClick={handleVerifyMail}
                          disabled={mailVerifyText === "Verifying ..."}
                        >
                          <Icon name="mail" className="mr-2 text-white"></Icon> {mailVerifyText}
                        </Button>
                      </li>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </span>
            </div>
           
          </div>
          <div className="data-item c-p-5" >
            <div className="data-col">
              <span className="data-label">Phone Number</span>
              <span className="data-value text-soft">
                {
                  userInfo.phone.startsWith("+") 
                    ? userInfo.phone 
                    : `${userInfo.phonecode.startsWith("+") ? userInfo.phonecode : `+${userInfo.phonecode}`}${userInfo.phone}`
                }
              </span>
              <span className="data-more data-col-end disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
            <div className="data-col data-col-end">
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
            </div>
          </div>
          <div className="data-item c-p-5">
            <div className="data-col">
              <span className="data-label">Messenger Type</span>
              <span className="data-value text-soft">{(!userInfo.messenger_type) ? "None" : userInfo.messenger_type}</span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item c-p-5">
            <div className="data-col">
              <span className="data-label">ID/Number</span>
              <span className="data-value text-soft">{(!userInfo.messenger_name) ? "None" : userInfo.messenger_name}</span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item c-p-5" >
            <div className="data-col">
              <span className="data-label">Address</span>
              <span className="data-value word-wrap break-word">
                {userInfo.address_line1 ? userInfo.address_line1+', ' : '' } 
                {userInfo.address_line2 ? userInfo.address_line2+', ' : ''}
                {userInfo.city ? userInfo.city+', ' : ''} {userInfo.state ? userInfo.state+', ' : ''}
              
                {userInfo.country} 
              </span>
            </div>
            <div className="data-col data-col-end">
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
            </div>
          </div>
        </div>
      </Block>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
